import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ThanksPage = props => {
  return (
    <Layout>
      <SEO title="Thanks" path="/thanks" />
      <section className="bg-gray-100 py-24">
        <div className="max-w-4xl px-5 mx-auto text-center">
          <header className="text-center mb-5">
            <h2 className="text-primary-500 text-4xl">Message received!</h2>
          </header>
          <div className="text-xl text-gray-700">
            <p>I'll get back to you as soon as I can.</p>
            <p>Randy Lough</p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ThanksPage
